
export const frText = {
    "Mother and Newborn Information": "Données concernant les mères et les nouveaunés",
    "for Tracking Outcomes and Results": "qui permettent un suivi des issues et des résultats",
    "(MONITOR) - ": "(MoNITOR) —",
    "online indicator Toolkit": "outils en ligne pour les indicateurs",
    "MONITOR is technical advisory group to WHO on measurement, metrics and monitoring of maternal and newborn health co-convened by the Departments of Maternal, Newborn, Child and Adolescent Health (MCA) and Sexual and Reproductive Health and Research (SRH).": "Le groupe consultatif technique MoNITOR conseille l’OMS sur les moyens de mesure, les indicateurs et\nle suivi de la santé de la mère et du nouveauné, sur convocation conjointe des Départements Santé de la\nmère, du nouveauné, de l’enfant et de l’adolescent (MCA) et Santé sexuelle et reproductive, et\nrecherche (SRH).",
    " See more ": "En savoir plus",
    "The MoNITOR online Toolkit includes:": "Les outils en ligne MoNITOR incluent :",
    "A searchable indicator database which contain 167 indicators related to maternal and newborn health, including core, optional and aspirational indicators": "une base de données consultable qui contient 167 indicateurs en rapport avec la santé de la\nmère et du nouveauné, dont des indicateurs essentiels, facultatifs et aspirationnels ;",
    "Indicator metadata reference sheets for 25 core indicators": "des fiches de référence sur les métadonnées pour 25 indicateurs de base.",
    "Recommendations and case studies on indicator testing and validation": "Recommandations et études de cas pour le test et la validation des indicateurs",
    "Reference Sheets": "Fiches de référence",
    "Download Case Studies": "Télécharger des études de cas",
    "Export Database": "Exporter la base de données",
    "Search for an indicator:": "Rechercher un indicateur",
    "Indicator Name:": "Intitulé de l’indicateur:",
    "Enter topic": "Entrer le sujet",
    "Indicator type:": "Type d’indicateur:",
    "Not selected": "Non séléctionné",
    "Input": "Intrants",
    "Output": "Extrants",
    "Outcome": "Résultats",
    "Impact": "Impact",
    "Domain:": "Domaine:",
    "Fertility": "Fécondité",
    "Financing": "Financement",
    "Governance": "Gouvernance",
    "Health Information": "Information sanitaire",
    "Health work force": "Health personnel",
    "Mortality": "Mortalité",
    "Other health status": "Autre état de santé",
    "Risk factors and behaviours": "Facteurs de risque et conduites à risque",
    "Service access and availability": "Accès aux services et disponibilité des services",
    "Service coverage": "Couverture des services",
    "Service quality and safety": "Qualité et sécurité des services",
    "Supply chain": "Chaîne logistique",
    "All": "Tous",
    "Intrapartum": "Intrapartum",
    "Antenatal": "Per-partum",
    "Antenatal Postnatal": "Prénatal Postnatal",
    "Antenatal intrapartum": "Intrapartum prénatal",
    "Intrapartum Postnatal": "Intrapartum Postnatal",
    "Postnatal": "Postnatals",
    "CRVS": "RSEC",
    "National surveillance system": "Système national de surveillance",
    "HH Survey": "Enquête ménage",
    "Health facility assessment": "Évaluation des établissements de santé",
    "HMIS/ DHIS2": "Système d'information sur la gestion de la santé / DHIS2",
    "Other": "Autre",
    "Clear Filters": "Effacer les filtres",
    "Indicator level for interest and use:": "Niveau de l’indicateur d’intérêt et utilisation",
    "Global": "Mondial",
    "National": "National",
    "Sub-National": "Infranational",
    "Indicator": "Type",
    "type": "d’indicateur",
    "Measurement": "Niveau",
    "level": "de mesure",
    "Indicator Name": "Intitulé de l’indicateur",
    "Domain": "Domaine",
    "Continuum": "Continuité",
    "of care": "des soins",
    "Status": "Statut",
    "Quality": "Qualité",
    "of Care": "des soins",
    "Showing": "Mostrando",
    "results": "resultados",
    "Back to indicators listing": "Retour à la liste des indicateurs",
    "Measurement level": "Niveau de mesure",
    "Indicator type": "Type d’indicateur",
    "Indicator level": "Niveau indicateur",
    "Numerator:": "Numérateur:",
    "Denominator:": "Dénominateur:",
    "Continuum of care:": "Continuité des soins",
    "Use:": "Utilisation:",
    "Quality of care:": "Qualité des soins:",
    "Status:": "Statut:",
    "Core 100:": "Noyau 100 :",
    "Adolescent health indicators list:": "Liste des indicateurs de santé des adolescents :",
    "60 global strategy:": "60 stratégie globale :",
    "QED MNH Common Ind:": "Indicateurs communs de qualité, équité, et dignité de la santé maternelle et néonatale",
    "EPMM:": "Initiative pour mettre un terme aux décès maternels évitables",
    "ENAP:": "Plan d’action « Chaque Nouveau-né »",
    "Countdown to 2030:": "Compte à rebours pour 2030",
    "SDGs:": "ODD",
    "The UN Commission on Life-Saving Commodities for Women and Child (UNCoLSC) indicator:": "L'indicateur de la Commission des Nations Unies sur les produits d'importance vitale pour les femmes et les enfants (UNCoLSC) :",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator": "OMS/UNICEF RMNCAH HMIS Analyse des données et utilisation l'Outil des indicateurs de base",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator": "OMS/UNICEF RMNCAH HMIS Analyse des données et utilisation l'Outil des indicateurs additionnels",
    "ANC monitoring framework": "Cadre de suivi des soins prénatals",
    "Operational Notes:": "Remarques opérationnelles :",
    "Back to indicator view": "Retour à la vue des indicateurs",
    "Download sheet": "Télécharger la fiche",
    "Privacy Policy": "Politique de confidentialité",
    "About us": "À propos de nous",
    "WHO Copyright": "Droit d'auteur OMS",
    "Feasible Data Sources:": "Sources de données possibles :"
}
