import React from 'react';
import {Container} from 'react-bootstrap';
import CardView from './CardView';
import {i18n, LanguageType, RelatedLink, relatedLinks} from "../i18n/utils";

export default class RelatedLinksView extends React.Component<{ language: LanguageType }, { relatedLinks: RelatedLink[] }> {

	constructor(props: any) {
		super(props);
		this.state = {
			relatedLinks: relatedLinks[this.props.language],
		}
	}

	componentDidMount() {
		(window as any).ga.pageview(window.location.pathname, window.location, "Related Links");
	}

	componentDidUpdate(prevProps: Readonly<{ language: LanguageType }>, prevState: Readonly<{ relatedLinks: RelatedLink[] }>, snapshot?: any) {
		if(prevProps.language !== this.props.language) {
			this.setState({
				relatedLinks: relatedLinks[this.props.language],
			})
		}
	}

	render(){
		return (
			<div className="ViewListing">
				<Container>
					<a onClick={() => window.history.back()} className="back-to-list">
						<i className="fas fa-long-arrow-alt-left"/> {i18n(this.props.language, "Back to indicator view")}
					</a>

					<div className="list-all-card d-flex flex-row justify-content-between align-items-start flex-wrap">
						{this.state.relatedLinks!.map((cardInfo: RelatedLink) =>
							<CardView key={cardInfo.name} cardInfo={cardInfo} language={this.props.language}/>
						)}

						<div className="card-view-empty"/>
						<div className="card-view-empty"/>
						<div className="card-view-empty"/>
					</div>

				</Container>
			</div>
		)
	}
};
