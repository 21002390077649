import React from 'react';
import './CardView.css';
import {i18n, LanguageType, RelatedLink} from "../i18n/utils";

export default class CardView extends React.Component<{ cardInfo: RelatedLink; language: LanguageType }, any> {
    render(){
		return (
			<div className="card-view d-flex flex-column justify-content-between align-items-center">
				<div className="ico-placeholder">
					<i className="fas fa-file-pdf"/>
				</div>

				<h4 className="card-name" title={this.props.cardInfo.name}>
					{this.props.cardInfo.name}
				</h4>

				<p className="card-container" title={this.props.cardInfo.description}>
					{`${this.props.cardInfo.description.split(' ').slice(0, 15).join(' ')}...`}
				</p>

				<a href={`${process.env.PUBLIC_URL}/related-sheets/${this.props.cardInfo.link}`}
				   download
				   className="btn btn-warning download-pdf-now">
					{i18n(this.props.language, "Download sheet")} <i className="far fa-file-pdf"/>
				</a>
			</div>
		)
	}
};
