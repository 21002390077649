import {RelatedLink} from "../utils";

export const esRelatedLinks: RelatedLink[] = [
    {
        name: "1. Tasa de natalidad entre las adolescentes",
        description: "Se estima que 295 000 mujeres murieron por complicaciones relacionadas con el embarazo o el parto en 2017.",
        link: "es/1. Tasa de natalidad entre las adolescentes.pdf",
    },
    {
        name: "Atenciขn prenatal (ocho visitas)",
        description: "La atención prenatal es un componente fundamental para mejorar la salud de la madre y el recién nacido ",
        link: "es/2. Atenciขn prenatal (ocho visitas).pdf",
    },
    {
        name: "Atenciขn prenatal (al menos cuatro visitas)",
        description: "La atención prenatal es un componente fundamental para mejorar la salud de la madre y el recién nacido y proporciona",
        link: "es/3. Atenciขn prenatal (al menos cuatro visitas).pdf",
    },
    {
        name: "Registro de los nacimientos",
        description: "El registro de nacimientos es un derecho humano fundamental según la Convención de las Naciones\n" +
            "Unidas sobre los Derechos del Niño",
        link: "es/4. Registro de los nacimientos.pdf",
    },
    {
        name: "Partos asistidos por personal de salud cualificado",
        description: "La mayoría de las complicaciones obstétricas podrían prevenirse o gestionarse si las mujeres tuvieran\n" +
            "acceso a un asistent",
        link: "es/5. Partos asistidos por personal de salud cualificado.pdf",
    },
    {
        name: "Tasa de ces reas.pdf",
        description: "La modalidad de parto es un indicador clave sobre el parto. El parto vaginal espontáneo es la modalidad más frecuente, seguida",
        link: "es/6. Tasa de ces reas.pdf",
    },
    {
        name: "Plan nacional de aplicaciขn con estimaciขn de costes",
        description: "Los expertos en salud mundial coinciden en que, para llegar a todas las mujeres, los recién nacidos y los niños —en particular,",
        link: "es/7. Plan nacional de aplicaciขn con estimaciขn de costes.pdf",
    },
    {
        name: "Inicio temprano de la lactancia materna",
        description: "",
        link: "es/8. Inicio temprano de la lactancia materna.pdf",
    },
    {
        name: "Lactancia materna exclusiva",
        description: "La lactancia materna es una de las medidas de salud preventiva más importantes tanto para la madre como para el niño",
        link: "es/9. Lactancia materna exclusiva.pdf",
    },
    {
        name: "Establecimientos funcionales de atenci¢n obst‚trica y neonatal de urgencia",
        description: "Los programas de salud de la madre y el recién nacido (SMR) que tienen por objetivo mejorar los resultados de salud",
        link: "es/10. Establecimientos funcionales de atenci¢n obst‚trica y neonatal de urgencia.pdf",
    },
    {
        name: "Prevalencia de la insuficiencia ponderal del reci‚n nacido",
        description: "El peso al nacer es un indicador fiable de la salud y la nutrición de la madre y el recién nacido. La desnutrición intrauterina aumenta el riesgo de muerte en los primeros meses y años de vida del niño. Los que sobreviven suelen",
        link: "es/11. Prevalencia de la insuficiencia ponderal del reci‚n nacido.pdf",
    },
    {
        name: "Causas de las defunciones maternas",
        description: "Se estima que 295 000 mujeres (intervalo de incertidumbre del 80%: de 279 000 a 340 000) murieron por complicaciones relacionadas con el embarazo o el parto en 2017 (1). Las defunciones maternas incluyen la",
        link: "es/12. Causas de las defunciones maternas.pdf",
    },
    {
        name: "Registro de defunciones",
        description: "Las estadísticas vitales consisten en una recopilación de datos sobre los acontecimientos que se producen",
        link: "es/13. Registro de defunciones.pdf",
    },
    {
        name: "Razขn de mortalidad materna",
        description: "Se estima que en 2017 murieron 295 000 mujeres (intervalo de incertidumbre del 80%:\n" +
            "de 279 000 a 340 000)",
        link: "es/14. Razขn de mortalidad materna.pdf",
    },
    {
        name: "Causas de las muertes neonatales",
        description: "Los primeros 28 días de vida son un periodo vulnerable para la supervivencia infantil y se estima que en 2019 murieron 2,4 (intervalo de incertidumbre: de 2,3 a 2,7) millones de recién nacidos (1).",
        link: "es/15. Causas de las muertes neonatales.pdf",
    },
    {
        name: "Tasa de mortalidad neonatal",
        description: "Los primeros 28 días de vida son un periodo vulnerable para la supervivencia infantil y se estima que en 2019 murieron 2,4 (intervalo de incertidumbre: de 2,3 a 2,7) millones de recién nacidos",
        link: "es/16. Tasa de mortalidad neonatal.pdf",
    },
    {
        name: "Reanimaciขn neonatal con bolsa y mascarilla",
        description: "Los primeros 28 días de vida son un periodo vulnerable para la supervivencia infantil y se estima que en 2019 murieron ",
        link: "es/17. Reanimaciขn neonatal con bolsa y mascarilla.pdf",
    },
    {
        name: "Reci‚n nacidos pesados al nacer",
        description: "El peso al nacer es un indicador fiable de la salud y la nutrición de la madre y el recién nacido. Medir y documentar",
        link: "es/18. Reci‚n nacidos pesados al nacer.pdf",
    },
    {
        name: "Cobertura de la atenci¢n posnatal reci‚n nacidos",
        description: "La gran mayoría de las muertes de recién nacidos se produce en países de ingresos bajos y medianos, principalmente en el hogar, sin una atención especializada que podría aumentar en gran medida",
        link: "es/19. Cobertura de la atenci¢n posnatal reci‚n nacidos.pdf",
    },
    {
        name: "Cobertura de la atenciขn puerperal mujeres",
        description: "El periodo postnatal se define como el tiempo que sigue al parto hasta seis semanas después del nacimiento y es una fase crítica en la vida de las madres y los recién nacidos. La mayoría de las muerte",
        link: "es/20. Cobertura de la atenciขn puerperal mujeres.pdf",
    },
    {
        name: "Prevalencia del tamaคo pequeคo para la edad gestacional",
        description: "Los recién nacidos pequeños para la edad gestacional se definen como aquellos cuyo peso se encuentra por debajo del percentil 10",
        link: "es/21. Prevalencia del tamaคo pequeคo para la edad gestacional.pdf",
    },
    {
        name: "Tasa de mortinatalidad",
        description: "La mortinatalidad es uno de los resultados adversos del embarazo más comunes en todo el mundo (1). La mortinatalidad o muerte fetal se define como el nacimiento de un bebé sin signos de vida (por ejemplo, no llora, no se mueve,",
        link: "es/22. Tasa de mortinatalidad.pdf", },
    {
        name: "Detecciขn de la sกfilis durante la atenciขn prenatal",
        description: "La sífilis es una infección de transmisión sexual que se adquiere principalmente por contacto sexual oral, anal o vaginal.",
        link: "es/23. Detecciขn de la sกfilis durante la atenciขn prenatal.pdf",
    },
    {
        name: "Tratamiento de la septicemia infecciขn neonatal",
        description: "Los primeros 28 días de vida son un periodo vulnerable para la supervivencia infantil y se estima que\n" +
            "en 2016 murieron 2,6 millones de recién nacidos (1). Aunque se han realizado progresos desde 1990, la mortalidad",
        link: "es/24. Tratamiento de la septicemia infecciขn neonatal.pdf",
    },
    {
        name: "Uterotขnicos para prevenir la hemorragia posparto",
        description: "La mayoría de las complicaciones obstétricas podrían prevenirse o tratarse mediante un acceso",
        link: "es/25. Uterotขnicos para prevenir la hemorragia posparto.pdf",
    },
];
