import React from 'react';
import './ViewProject.css';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Indicator } from "../indicator";
import { pdfEndpoint } from "../endpoints";
import {iconMeasurements, typesClassName} from "../results/indicator-row/IndicatorRow";
import {i18n, LanguageType} from '../i18n/utils';

interface ViewProjectProps {
	indicator: Indicator | undefined;
	language: LanguageType;
}

export default class ViewProject extends React.Component<ViewProjectProps, any> {

	constructor(props: ViewProjectProps) {
		super(props);
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		(window as any).ga.pageview(window.location.pathname, window.location, `Indicator Id ${this.props.indicator!.id}`);
	}

	public exportPdf(indicatorId: number) {
		fetch(`${pdfEndpoint}/${indicatorId}`, {
			method: "POST",
		})
			.then((response) => response.text())
			.then((pdfName) => {
				window.open(`${pdfEndpoint}/${pdfName}`);
			})
			.catch(error => {
				console.log(error);
			});
	}

	private i18n (key: string): string {
		return i18n(this.props.language, key);
	}

    render(){
		return (
			<div className="ViewProject">
				<Container>
					<Link to={`/`} className="back-to-list">
						<i className="fas fa-long-arrow-alt-left"/> {this.i18n("Back to indicators listing")}
					</Link>

					<div className="body-project">
						<div className="main-section">
							<div className="header d-flex flex-row justify-content-start">
								<p className={`level d-flex align-items-center ${(typesClassName[this.props.language] as any)[this.props.indicator!.type.toLowerCase()]}`}>
									{iconMeasurements[this.props.language][this.props.indicator!.measurement_level]}
									<span>{this.props.indicator!.measurement_level} {this.i18n("Measurement level")}</span>
								</p>

								<p className={`type d-flex flex-column ${(typesClassName[this.props.language] as any)[this.props.indicator!.type.toLowerCase()]}`}>
									<span className="type-name">{this.props.indicator!.type}</span>
									<span className="type-sub-name">{this.i18n("Indicator type")}</span>
								</p>

								<p className="indicator-level d-flex flex-column">
									<span className="type-name">
										{this.props.indicator!.indicator_level_for_interest_and_use}
									</span>
									<span className="type-sub-name">{this.i18n("Indicator level")}</span>
								</p>
							</div>

							<div className="panel-information d-flex flex-row justify-content-between align-items-start">
								<div className="detailed-info">
									<h1>
										{this.props.indicator!.name}
									</h1>

									<h2>
										{this.props.indicator!.definition}
									</h2>

									<div className="numerator-denominator d-flex flex-row justify-content-start align-items-start">
										<p className="numerator d-flex flex-column">
											<span className="name">{this.i18n("Numerator:")}</span>
											<span className="info">{this.props.indicator!.numerator}</span>
										</p>

										<p className="denominator d-flex flex-column">
											<span className="name">{this.i18n("Denominator:")}</span>
											<span className="info">{this.props.indicator!.denominator}</span>
										</p>
									</div>

									<div className="list-service d-flex flex-row justify-content-start">
										<p className={`crvs service d-flex align-items-start ${
												!this.props.indicator!.crvs && 'deactive'
											}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("CRVS")}</span>
										</p>

										<p className={`national-system service d-flex align-items-start ${
												!this.props.indicator!.national_surveillance_system && 'deactive'
											}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("National surveillance system")}</span>
										</p>

										<p className={`national-system service d-flex align-items-start ${
											!this.props.indicator!.hh_survey && 'deactive'
										}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("HH Survey")}</span>
										</p>

										<p className={`national-system service d-flex align-items-start ${
											!this.props.indicator!.health_facility_assessment && 'deactive'
										}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("Health facility assessment")}</span>
										</p>


										<p className={`national-system service d-flex align-items-start ${
											!this.props.indicator!.hmis_dhis2 && 'deactive'
										}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("HMIS/ DHIS2")}</span>
										</p>

										<p className={`national-system service d-flex align-items-start ${
											!this.props.indicator!.other && 'deactive'
										}`}>
											<i className="fas fa-check"/>
											<span>{this.i18n("Other")}</span>
										</p>
									</div>
								</div>

								<div className="sidebar">
									<p className="domain d-flex flex-column">
										<span className="title">{this.i18n("Domain:")}</span>
										<span className="field">{this.props.indicator!.domain}</span>
									</p>

									<p className="continuum d-flex flex-column">
										<span className="title">{this.i18n("Continuum of care:")}</span>
										<span className="field">{this.props.indicator!.continuum_of_care}</span>
									</p>

									<p className="use d-flex flex-column">
										<span className="title">{this.i18n("Use:")}</span>
										<span className="field">{this.props.indicator!.indicator_use}</span>
									</p>

									<p className="quality d-flex flex-column">
										<span className="title">{this.i18n("Quality of care:")}</span>
										<span className="field">{this.props.indicator!.quality_of_care}</span>
									</p>

									<p className="status d-flex flex-column">
										<span className="title">{this.i18n("Status:")}</span>
										<span className="field">{this.props.indicator!.status}</span>
									</p>

									<a href="/related-links" className="btn btn-primary go-to-listing">
										<i className="far fa-folder-open"/> {this.i18n("Reference Sheets")}
									</a>
								</div>
							</div>
						</div>

						<div className="list-more-info">
							<ul className="d-flex flex-row justify-content-start align-items-start flex-wrap">
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("Core 100:")}</span>
									<span className="value">{this.props.indicator!.core_100}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("Adolescent health indicators list:")}</span>
									<span className="value">{this.props.indicator!.adolescent_health_indicators_list}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("60 global strategy:")}</span>
									<span className="value">{this.props.indicator!.global_strategy_60}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("QED MNH Common Ind:")}</span>
									<span className="value">{this.props.indicator!.qed_mnh_common_ind}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("EPMM:")}</span>
									<span className="value">{this.props.indicator!.qed_mnh_common_ind}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("ENAP:")}</span>
									<span className="value">{this.props.indicator!.enap}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("Countdown to 2030:")}</span>
									<span className="value">{this.props.indicator!.countdown_2030}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("SDGs:")}</span>
									<span className="value">{this.props.indicator!.sdgs}</span>
								</li>
								<li className="d-flex flex-row align-items-start">
									<span className="label">{this.i18n("The UN Commission on Life-Saving Commodities for Women and Child (UNCoLSC) indicator:")}</span>
									<span className="value">{this.props.indicator!.uncolsc}</span>
								</li>
							</ul>

							<div className="props d-flex flex-row justify-content-start align-items-start">
								{/* You can use also this class: 'deactivate' */}
								<p className={`national-system service d-flex align-items-start ${!this.props.indicator!.rmncah_hmis_data_analysis_and_use_toolkit_core_indicator && 'deactive'}`}>
									<i className="fas fa-check"/>
									<span>{this.i18n("WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator")}</span>
								</p>

								<p className={`national-system service d-flex align-items-start ${!this.props.indicator!.rmncah_hmis_data_analysis_and_use_toolkit_additional_indicator && "deactive"}`}>
									<i className="fas fa-check"/>
									<span>{this.i18n("WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator")}</span>
								</p>

								<p className={`national-system service d-flex align-items-start ${!this.props.indicator!.adolescent_health_indicators_list && "deactive"}`}>
									<i className="fas fa-check"/>
									<span>{this.i18n("ANC monitoring framework")}</span>
								</p>
							</div>
						</div>

						<div className="operational-notes">
							<p className="intro">{this.i18n("Operational Notes:")}</p>

							<p className="note">
								{this.props.indicator!.operational_notes}
							</p>
						</div>
					</div>
				</Container>
			</div>
		);
	}
}
