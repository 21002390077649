import React from 'react';
import './Results.css';
import { Container } from 'react-bootstrap';
import IndicatorRow from './indicator-row/IndicatorRow';
import IndicatorPagination from "./pagination/indicator-pagination";
import { Indicator } from "../indicator";
import {i18n, LanguageType} from '../i18n/utils';

interface ResultsProps {
	indicators: Indicator[] | undefined;
	allIndicatorsLength: number;
	pageCount: number;
	onPageClick: (pageNumber: number) => void;
	active: number;
	onSortChange: (value:any) => void;
	language: LanguageType;
}

export default class Results extends React.Component<ResultsProps, any> {
	constructor(props:any) {
		super(props);

		this.state = {
			type: '',
			order: ''
		}
	}

	updateStateSortBy(type:string, target: any) {
		// Current value from clicking column:
		let clickedType = type;

		// Check if current state.type is empty or have diferent value:
		let emptyOrNotMatch = this.state.type === '' || this.state.type !== clickedType;

		// last saved order:
		let currentOrder = this.state.order;

		if (emptyOrNotMatch) {
			// Save new value [order] in state:
			this.setState({
				order: 'asc',
				type: clickedType,
				}, () => {
					this.sendSortingToApp(target);
				}
			);
		}
		else {
			switch (currentOrder) {
				case 'asc':
					currentOrder = 'desc';
					break;
				case 'desc':
					currentOrder = 'none';
					break;
				case 'none':
					currentOrder = 'asc';
					break;
				default:
					console.log('Problem in changing the order!!!');
					break;
			}

			// Save new value [order] in state:
			this.setState({
				order: currentOrder,
				type: currentOrder === 'desc' ? clickedType : '',
				}, () => {
					this.sendSortingToApp(target);
				}
			);
		}
	}

	sendSortingToApp(target:any) {
		let nameSort = this.state.order !== 'desc' ? this.state.type : `-${this.state.type}`;

		// Send sort type to AppState:
		this.props.onSortChange(nameSort);

		// Update icons:
		const icons = document.querySelectorAll(".sort-icon");
		// Select all sort icons and set default classes:
		icons.forEach(icon => icon.className = "sort-icon fas fa-sort");

		// Update sort icon for target column:
		let clickedElementIcon = this.state.type ? document.querySelector(`div.table-col-th.${this.state.type} p i`) : null;
		if (clickedElementIcon && this.state.order === 'asc') {
			clickedElementIcon!.className = 'sort-icon fas fa-sort-down';
		}
		else if (clickedElementIcon && this.state.order === 'desc') {
			clickedElementIcon!.className = 'sort-icon fas fa-sort-up';
		}
		else if (clickedElementIcon && (this.state.order === 'none' || this.state.order === '')) {
			clickedElementIcon!.className = 'sort-icon fas fa-sort';
		}
	}

	private i18n (key: string): string {
		return i18n(this.props.language, key);
	}

	render() {
		return (
			<div className="Results">
				<div className="search-results-table">
					<Container>
						<div className="d-flex flex-column results-global-container">
							{/* 9 columns */}
							<div className="table-row-head d-flex flex-row justify-content-between d-flex align-items-center">
								<div className="table-col-th type">
									<p onClick={(e) => this.updateStateSortBy('type', e.target)}>
										{this.i18n("Indicator")} <br/> {this.i18n("type")}
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th measurement_level">
									<p onClick={(e) => this.updateStateSortBy('measurement_level', e.target)}>
										{this.i18n("Measurement") } <br/> {this.i18n("level")}
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th name">
									<p onClick={(e) => this.updateStateSortBy('name', e.target)}>
										{this.i18n("Indicator Name")}
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th domain">
									<p onClick={(e) => this.updateStateSortBy('domain', e.target)}>
										{this.i18n("Domain")}
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th continuum_of_care">
									<p onClick={(e) => this.updateStateSortBy('continuum_of_care', e.target)}>
										{this.i18n("Continuum") } <br/> {this.i18n("of care") }
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th status">
									<p onClick={(e) => this.updateStateSortBy('status', e.target)}>
										{this.i18n("Status") }
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th quality_of_care">
									<p onClick={(e) => this.updateStateSortBy('quality_of_care', e.target)}>
										{this.i18n("Quality")} <br/> {this.i18n("of Care")}
										<i className="sort-icon fas fa-sort"/>
									</p>
								</div>

								<div className="table-col-th">
									<p className="empty-cell"/>
								</div>
							</div>

							{this.props.indicators!.map((indicator: any) =>
								<IndicatorRow language={this.props.language} key={indicator.id} indicator={indicator}/>
							)}

							<div className="results-footer d-flex justify-content-between flex-row align-items-center align-content-center flex-nowrap">
								<p className="total-results">
									{this.i18n("Showing")} {this.props.allIndicatorsLength} {this.i18n("results")}
								</p>

								<IndicatorPagination
									active={this.props.active}
									pageCount={this.props.pageCount}
									onPageClick={(pageNumber: number) => this.props.onPageClick(pageNumber)}
								/>
							</div>
						</div>
					</Container>
				</div>
			</div>
		);
	}
}

