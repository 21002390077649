import {RelatedLink} from "../utils";

export const enRelatedLinks: RelatedLink[] = [
	{
		name: 'Indicator sheets combined',
		description: 'The file contains all the reference sheets combined',
		link: 'Indicator sheets together_dec2020.pdf',
	},
	{
		name: 'Adolescent Birth Rate',
		description: 'The adolescent birth rate (ABR) is defined as the annual number of births in women aged 15–19\n' +
			'years old per 1000 women in the same respective age group (2). The adolescent birth rate is\n' +
			'also referred to as the age-specific fertility rate (ASFR) for women aged 15–19 years old. ',
		link: 'Monitor Indicator sheet Adolescent birth rate.pdf'
	},
	{
	  name: 'Antenatal Care - Eight Visits',
	  description: 'The number of women of reproductive age with a live birth in a specified reference period who received\n' +
		  'ANC four or more times from any provider is expressed as a percentage of women in the same age\n' +
		  'range with a live birth in the same period.',
	  link: 'Monitor Indicator sheet Antenatal care eigth visits.pdf',
	},
	{
	  name: 'Antenatal Care - Four Visits',
	  description: 'The number of women of reproductive age with a live birth in a specified reference period who received\n' +
		  'ANC four or more times from any provider is expressed as a percentage of women in the same age\n' +
		  'range with a live birth in the same period',
	  link: 'Monitor Indicator sheet Antenatal care at least 4 visits.pdf'
	},
	{
	  name: 'Birth Registration',
	  description: 'The number of children under 5 years of age with a birth certificate or whose birth was\n' +
		  'registered with a civil authority during a specified reference period is expressed as a percentage\n' +
		  'of children under the age of 5 years old in the same reference period (3).',
	  link: 'Monitor Indicator sheet Birth registration.pdf',
	},
	{
		name: 'Births Attendant by Skilled Health Personnel',
		description: 'The number of women of reproductive age with a live birth attended by skilled health personnel\n' +
			'(generally doctors, nurses, or midwives) during delivery in a specified reference period is expressed as a\n' +
			'percentage of women in the same age range with a live birth in the same period.',
		link: 'Monitor Indicator sheet Births attended by skilled health personnel.pdf',
	},
	{
	  name: 'Caesarean Section Rate',
	  description: 'The number of women of reproductive age who delivered by CS in a specified reference period\n' +
		  'is expressed as a percentage of women in the same age range with a delivery in the same\n' +
		  'period.',
	  link: 'Monitor Indicator sheet Caesarean section rate.pdf',
	},
	{
		name: 'Costed national implementation',
	    description: 'This indicator reflects whether countries prioritize improved\n' +
			'MNCH and reduction of mortality and morbidity.',
	    link: 'Monitor Indicator sheet Costed national implementation.pdf'
	},
	{
		name: 'Early Initiation of Breastfeeding',
		description: 'The number of newborns who breastfed1 within one hour of birth during a specified reference\n' +
			'period is expressed as a percentage of the total number of live births in the same period.',
	    link: 'Monitor Indicator sheet Early initiation of breastfeeding.pdf',
	},
	{
		name: 'Exclusive Breastfeeding',
		description: 'The number of infants 0−5 months (< 6 months) of age who are fed exclusively with breast milk\n' +
			'during a specified reference period is expressed as a percentage of the total number of infants\n' +
			'0−5 months (< 6 months) of age in the same period',
		link: 'Monitor Indicator sheet Exclusive breastfeeding rate.pdf'
	},
	{
		name: 'Availability of functional emergency obstretric',
		description: 'The number of obstetric care facilities that provided EmONC signal functions in the last three\n' +
			'months per 500 000 total population.',
		link: 'Monitor Indicator sheet Availability of functional emergency obstretric.pdf'
	},
	{
		name: 'Prevalence of low birthweight among newborns',
		description: 'The number of live-born neonates with weight less than 2500 g at birth in a specified reference period is\n' +
			'expressed as a percentage of the total number of live births in the same period. ',
		link: 'Monitor Indicator sheet Prevalence of low birthweight among newborns.pdf'
	},
	{
		name: 'Maternal Cause of Death',
		description: 'The number of maternal deaths from a specified cause related to or aggravated by pregnancy\n' +
			'or its management (excluding accidental or incidental causes) during pregnancy, childbirth, or\n' +
			'within 42 days of termination of pregnancy for a specified time period is expressed as a \n' +
			'percentage of all maternal deaths in the same period (distribution of main causes of death\n' +
			'using ICD-MM)',
		link: 'Monitor Indicator sheet Maternal cause of death.pdf',
	},
	{
		name: 'Maternal Death Registration',
		description: 'Percentage of maternal deaths registered with cause of death specified based on ICD-MM\n' +
			'codes',
		link: 'Monitor Indicator sheet Maternal death registration.pdf'
	},
	{
		name: 'Maternal Mortality Ratio',
		description: 'The annual number of female deaths from any cause related to or aggravated by pregnancy or\n' +
			'its management (excluding accidental or incidental causes) during pregnancy and childbirth or\n' +
			'within 42 days of termination of pregnancy, irrespective of the duration and site of the\n' +
			'pregnancy, expressed per 100 000 live births, for a specified time period',
		link: 'Monitor Indicator sheet Maternal mortality ratio.pdf',
	},
	{
		name: 'Neonatal Cause of Death',
		description: 'Percentage of neonatal deaths (death on day 0–27 of life) of a specified cause for a specified\n' +
			'time period (using ICD-PM) expressed as a percentage of all maternal deaths.\n',
		link: 'Monitor Indicator sheet Neonatal cause of death.pdf'
	},
	{
		name: 'Neonatal Mortality Rate',
		description: 'Probability that a child born in a specific year or period will die during the first 28 completed\n' +
			'days of life if subject to age-specific mortality rates of that period, expressed per 1000 live\n' +
			'births. Neonatal deaths (deaths among live births during the first 28 completed days of life)\n' +
			'may be subdivided into early neonatal deaths, occurring during the first seven days of life, and\n' +
			'late neonatal deaths, occurring after the seventh day but before the 28th completed day of\n' +
			'life. The number of children who died during the first 28 days of life is expressed as a rate per 1000\n' +
			'live births',
		link: 'Monitor Indicator sheet Neonatal mortality rate.pdf',
	},
	{
		name: 'Newborn resuscitation with bag and mask',
		description: 'The number of newborns not breathing/crying spontaneously within the first minute after birth\n' +
			'for whom additional resuscitation actions (stimulation and bag and mask) were taken during a\n' +
			'specified reference period is expressed as a percentage of the total number of live births1 in the\n' +
			'same period',
		link: 'Monitor Indicator sheet Newborn resuscitation with bag and mask.pdf'
	},
	{
		name: 'Newborns Weighed at Birth',
		description: 'The number of most recent live births that were weighed at birth in a specified reference\n' +
			'period is expressed as a percentage of the total number of most recent live births in the same\n' +
			'period.',
		link: 'Monitor Indicator sheet Newborns weighed at birth.pdf'
	},
	{
		name: 'Postnatal Care - Newborns',
		description: 'The number of women of reproductive age with a live birth in a specified reference period\n' +
			'where the newborn received a postnatal care (PNC) check with a health provider within two\n' +
			'days of birth is expressed as a percentage of women in the same age range with a live birth in\n' +
			'the same period.',
		link: 'Monitor Indicator sheet Postnatal care coverage newborn.pdf'
	},
	{
		name: 'Postnatal Care - Women',
		description: 'The number of women of reproductive age with a live birth in a specified reference period who\n' +
			'received a postnatal/postpartum care (PNC) check with a health provider within two days of\n' +
			'delivery is expressed as a percentage of women in the same age range with a live birth in the\n' +
			'same period.',
		link: 'Monitor Indicator sheet Postnatal care coverage women.pdf'
	},
	{
		name: 'Prevalence of Small for Gestational Age',
		description: 'The number of newborns that are SGA1 in a specified reference period is expressed as a percentage of\n' +
			'the total number of live births in the same period.',
		link: 'Monitor Indicator sheet Prevalence of small for gestational age.pdf'
	},
	{
		name: 'Stillbirth Rate',
		description: 'The number of fetuses born per year with no sign of life and born with birthweight of 1000 g, or\n' +
			'after 28 weeks’ gestation, or 35 cm or more body length is expressed as a rate per 1000 births\n' +
			'(live and stillbirths)',
		link: 'Monitor Indicator sheet Stillbirth rate.pdf'
	},
	{
		name: 'Antenatal care client syphilis screening',
		description: 'The number of antenatal clients screened for syphilis during a specified reference period is expressed as\n' +
			'a percentage of the total number of antenatal clients at first ANC visit during the same period.',
		link: 'Monitor Indicator sheet Antenatal care client syphilis screening.pdf'
	},
	{
		name: 'Newborns treated for neonatal sepsis',
		description: 'The number of newborns 0–28 days of age with suspected1 severe bacterial infection who\n' +
			'receive appropriate antibiotic therapy (at least one injection of antibiotic) during a specified\n' +
			'reference period is expressed as a percentage of the total number of live births2 in the same\n' +
			'period.',
		link: 'Monitor Indicator sheet Newborns treated for neonatal sepsis.pdf'
	},
	{
		name: 'Uterotonic for Prevention of Postpartum Haemorrhage',
		description: 'The number of women who gave birth in a facility who received a prophylactic uterotonic (e.g.\n' +
			'oxytocin) immediately1 after birth for prevention of PPH is expressed as a percentage of the\n' +
			'total number of women who gave birth in a facility in the same period.',
		link: 'Monitor Indicator sheet uterotonic for prevention of postpartum haemorrhage.pdf'
	},
];
