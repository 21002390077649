import {RelatedLink} from "../utils";

export const frRelatedLinks: RelatedLink[] = [
    {
        name: "Taux de natalite chez les adolescentes",
        description: "On estime que 295 000 femmes sont décédées de complications liées à la grossesse ou à l’accouchement en 2017. Les adolescentes sont exposées à un risque plus important de morbidité et de mortalité",
        link: "fr/1. Taux de natalite chez les adolescentes.pdf",
    },
    {   name: "Soins prenatals (huit visites)",
        description: "Les soins prénatals sont un élément essentiel pour améliorer la santé de la mère et du nouveau-né et servent de base à d’importantes",
        link: "fr/2. Soins prenatals (huit visites).pdf",
    },
    {
        name: "Soins prenatals au moins quatre visites",
        description: "Les soins prénatals sont un élément essentiel pour améliorer la santé de la mère et du nouveau -né et servent de base à d’importantes fonctions des soins de santé, notamment : promotion de la santé, dépistage et diagnostic",
        link: "fr/3. Soins prenatals au moins quatre visites.pdf",
    },
    {
        name: "Enregistrement des naissances",
        description: "Selon la Convention relative aux droits de l’enfant des Nations Unies (1), l’enregistrement des\n" +
            "naissances est un droit humain fondamental et un élément essentiel pour améliorer la santé des mères,\n" +
            "des nouveau-nés et des enfants (2)",
        link: "fr/4. Enregistrement des naissances.pdf",
    },
    {
        name: "Accouchements assistes par du personnel de sante qualifie",
        description: "La plupart des complications obstétricales pourraient être évitées ou prises en charge si les\n" +
            "femmes avaient accès à un accoucheur qualifié ou à du personnel de santé qualifié apte à\n" +
            "proposer une prise en charge",
        link: "fr/5. Accouchements assistes par du personnel de sante qualifie.pdf",
    },
    {
        name: "Taux de cesariennes",
        description: "Le mode d’accouchement est un indicateur clé de l’accouchement. L’accouchement spontané par",
        link: "fr/6. Taux de cesariennes.pdf",
    },
    {
        name: "Plans de mise en oeuvre nationaux chiffres en faveur de la SMNE",
        description: "Les experts en santé mondiale s’accordent à dire que chaque femme, nouveau -né et enfant ne pourront être pris en compte",
        link: "fr/7. Plans de mise en oeuvre nationaux chiffres en faveur de la SMNE.pdf",
    },
    {
        name: "Mise en route precoce de allaitement au sein",
        description: "L’allaitement est l’une des mesures sanitaires préventives les plus importantes pour la mère comme pour l’enfant. ",
        link: "fr/8. Mise en route precoce de allaitement au sein.pdf",
    },
    {
        name: "Allaitement au sein exclusif",
        description: "L’allaitement est l’une des mesures sanitaires préventives les plus importantes pour la mère comme pour l’enfant.",
        link: "fr/9. Allaitement au sein exclusif.pdf",
    },
    {
        name: "Structures sanitaires fonctionnelles dispensant des SONU",
        description: "Les programmes de santé de la mère et du nouveau-né visant à améliorer les résultats sanitaires et à réduire la mortalité et la morbidité doivent garantir que les femmes et leurs nouveau-nés",
        link: "fr/10. Structures sanitaires fonctionnelles dispensant des SONU.pdf",
    },
    {
        name: "Pr‚valence du faible poids de naissance chez les nouveau nes",
        description: "Le poids d’un nouveau-né à la naissance est un indicateur fiable de la santé et de l’état nutritionnel de la mère et du nouveau-né. Une dénutrition fœtale augmente le risque que l’enfant décède au cours des premiers mois ou années",
        link: "fr/11. Pr‚valence du faible poids de naissance chez les nouveau nes.pdf",
    },
    {
        name: "Cause du deces maternel",
        description: "On estime que 295 000 femmes (intervalle d’incertitude [IU] à 80 % : 279 000 à 340 000) sont décédées de",
        link: "fr/12. Cause du deces maternel.pdf",
    },
    {
        name: "Enregistrement des deces",
        description: "Les statistiques de l’état civil collectent des données sur les événements qui surviennent au cours de la vie d’une personne, notamment les caractéristiques pertinentes de ces événement",
        link: "fr/13. Enregistrement des deces.pdf",
    },
    {
        name: "Taux de mortalite maternelle",
        description: "On estime que 295 000 femmes (intervalle d’incertitude à 80 % : 279 000 à 340 000) sont décédées de",
        link: "fr/14. Taux de mortalite maternelle.pdf",
    },
    {
        name: "15. Cause du deces neonatal",
        description: "Les 28 premiers jours de vie sont une période de vulnérabilité pour la survie de l’enfant ; on estime que 2,4 (intervalle d’incertitude : 2,3 à 2,7) millions de nouveau-nés sont décédés en 2019 (1).",
        link: "fr/15. Cause du deces neonatal.pdf",
    },
    {
        name: "Taux de mortalite neonatale",
        description: "Les 28 premiers jours de vie sont une période de vulnérabilité pour la survie de l’enfant ; on estime que 2,4",
        link: "fr/16. Taux de mortalite neonatale.pdf",
    },
    {
        name: "Reanimation neonatale avec ballon et masque",
        description: "Les 28 premiers jours de vie sont une période de vulnérabilité pour la survie de l’enfant ; on estime que 2,4",
        link: "fr/17. Reanimation neonatale avec ballon et masque.pdf",
    },
    {
        name: "Nouveau nes peses … la naissance",
        description: "Le poids d’un nourrisson à la naissance est un indicateur fiable de la santé et de l’état nutritionnel de la mère",
        link: "fr/18. Nouveau nes peses … la naissance.pdf",
    },
    {
        name: "Couverture des soins postnatals nouveau nes",
        description: "La grande majorité des décès de nouveau-nés se produisent dans les pays à revenu faible ou intermédiaire, le plus souvent au domicile, à défaut de prestation de soins par du personnel qualifié qui aurait grandement augmenté les chances de survie du nourrisson (1). Les enfants qui meurent au",
        link: "fr/19. Couverture des soins postnatals nouveau nes.pdf",
    },
    {
        name: "20. Couverture des soins postnatals postpartum femmes",
        description: "La période postnatale commence après l’accouchement et se termine six semaines après la naissance",
        link: "fr/20. Couverture des soins postnatals postpartum femmes.pdf",
    },
    {
        name: "Prevalence de hypotrophie",
        description: "Les nouveau-nés petits pour l’âge gestationnel, ou hypotrophiques, sont ceux dont le poids est inférieur au 10e centile",
        link: "fr/21. Prevalence de hypotrophie.pdf",
    },
    {
        name: "Taux de mortinatalite",
        description: "Les mortinaissances comptent parmi les issues défavorables de la grossesse les plus fréquentes au monde (1)",
        link: "fr/22. Taux de mortinatalite.pdf",
    },
    {
        name: "Depistage de la syphilis pendant les soins prenatals",
        description: "La syphilis est une infection sexuellement transmissible qui est majoritairement acquise lors de rapports",
        link: "fr/23. Depistage de la syphilis pendant les soins prenatals.pdf",
    },
    {
        name: "Traitement du sepsis de infection neonatals",
        description: "Les 28 premiers jours de vie sont une période de vulnérabilité pour la survie de l’enfant ; on estime que 2,6 millions de nouveau",
        link: "fr/24. Traitement du sepsis de infection neonatals.pdf",
    },
    {
        name: "Uterotonique en prevention de hemorragie du postpartum",
        description: "La plupart des complications obstétricales pourraient être évitées ou prises en charge grâce à un accès",
        link: "fr/25. Uterotonique en prevention de hemorragie du postpartum.pdf",
    },
    {
        name: "Tous les indicateurs SMNN",
        description: "Tous les indicateurs SMNN",
        link: "fr/Tous les indicateurs SMNN.pdf",
    },
];
