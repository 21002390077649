import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GA4React from "ga-4-react";

const ga4react = new GA4React(
    'G-6XQ3898KF9',
);

(async () => {
    const ga = await ga4react.initialize();
    (window as any).ga = ga;
    ReactDOM.render(<App />, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
