export const enText = {
    "Mother and Newborn Information": "Mother and Newborn Information",
    "for Tracking Outcomes and Results": "for Tracking Outcomes and Results",
    "(MONITOR) - ": "(MONITOR) - ",
    "online indicator Toolkit": "online indicator Toolkit",
    "MONITOR is technical advisory group to WHO on measurement, metrics and monitoring of maternal and newborn health co-convened by the Departments of Maternal, Newborn, Child and Adolescent Health (MCA) and Sexual and Reproductive Health and Research (SRH).": "MONITOR is technical advisory group to WHO on measurement, metrics and monitoring of maternal and newborn health co-convened by the Departments of Maternal, Newborn, Child and Adolescent Health (MCA) and Sexual and Reproductive Health and Research (SRH).",
    " See more ": " See more ",
    "The MoNITOR online Toolkit includes:": "The MoNITOR online Toolkit includes:",
    "A searchable indicator database which contain 167 indicators related to maternal and newborn health, including core, optional and aspirational indicators": "A searchable indicator database which contain 167 indicators related to maternal and newborn health, including core, optional and aspirational indicators",
    "Indicator metadata reference sheets for 25 core indicators": "Indicator metadata reference sheets for 25 core indicators",
    "Recommendations and case studies on indicator testing and validation": "Recommendations and case studies on indicator testing and validation",
    "Reference Sheets": "Reference Sheets",
    "Download Case Studies": "Download Case Studies",
    "Export Database": "Export Database",
    "Search for an indicator:": "Search for an indicator:",
    "Indicator Name:": "Indicator Name:",
    "Enter topic": "Enter topic",
    "Indicator type:": "Indicator type:",
    "Not selected": "Not selected",
    "Input":"Input",
    "Output":"Output",
    "Outcome":"Outcome",
    "Impact":"Impact",
    "Domain:": "Domain:",
    "Fertility":"Fertility",
    "Financing":"Financing",
    "Governance":"Governance",
    "Health Information":"Health Information",
    "Health work force":"Health work force",
    "Mortality":"Mortality",
    "Other health status":"Other health status",
    "Risk factors and behaviours":"Risk factors and behaviours",
    "Service access and availability":"Service access and availability",
    "Service coverage":"Service coverage",
    "Service quality and safety":"Service quality and safety",
    "Supply chain":"Supply chain",
    "All": "All",
    "Intrapartum": "Intrapartum",
    "Antenatal": "Antenatal",
    "Antenatal Postnatal": "Antenatal Postnatal",
    "Antenatal intrapartum": "Antenatal intrapartum",
    "Intrapartum Postnatal": "Intrapartum Postnatal",
    "Postnatal": "Postnatal",
    "CRVS": "CRVS",
    "National surveillance system": "National surveillance system",
    "HH Survey": "HH Survey",
    "Health facility assessment": "Health facility assessment",
    "HMIS/ DHIS2": "HMIS/ DHIS2",
    "Other": "Other",
    "Clear Filters": "Clear Filters",
    "Indicator level for interest and use:": "Indicator level for interest and use:",
    "Global": "Global",
    "National": "National",
    "Sub-National": "Sub-National",
    "Indicator": "Indicator",
    "type": "type",
    "Measurement": "Measurement",
    "level": "level",
    "Indicator Name": "Indicator Name",
    "Domain": "Domain",
    "Continuum": "Continuum",
    "of care": "of care",
    "Status": "Status",
    "Quality": "Quality",
    "of Care": "of Care",
    "Showing": "Showing",
    "results": "results",
    "Back to indicators listing": "Back to indicators listing",
    "Measurement level": "Measurement level",
    "Indicator type": "Indicator type",
    "Indicator level": "Indicator level",
    "Numerator:": "Numerator:",
    "Denominator:": "Denominator:",
    "Continuum of care:": "Continuum of care:",
    "Use:": "Use:",
    "Quality of care:": "Quality of care:",
    "Status:": "Status:",
    "Core 100:": "Core 100:",
    "Adolescent health indicators list:": "Adolescent health indicators list:",
    "60 global strategy:": "60 global strategy:",
    "QED MNH Common Ind:": "QED MNH Common Ind:",
    "EPMM:": "EPMM:",
    "ENAP:": "ENAP:",
    "Countdown to 2030:": "Countdown to 2030:",
    "SDGs:": "SDGs:",
    "The UN Commission on Life-Saving Commodities for Women and Child (UNCoLSC) indicator:": "The UN Commission on Life-Saving Commodities for Women and Child (UNCoLSC) indicator:",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator": "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator": "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator",
    "ANC monitoring framework": "ANC monitoring framework",
    "Operational Notes:": "Operational Notes:",
    "Back to indicator view": "Back to indicator view",
    "Download sheet": "Download sheet",
    "Privacy Policy": "Privacy Policy",
    "About us": "About us",
    "WHO Copyright": "WHO Copyright",
    "Feasible Data Sources:": "Feasible Data Sources:",
}
