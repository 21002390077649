import React from 'react';
import './Footer.css';
import { Container, Col, Row } from 'react-bootstrap';

import {i18n, LanguageType} from '../i18n/utils';
import englishLogo from "../assets/WHO-EN-W-H.png";
import frenchLogo from "../assets/WHO-FR-W-H.png";
import spanishLogo from "../assets/WHO-SP-W-H.png";

const whiteLogos = {
	en: englishLogo,
	fr: frenchLogo,
	es: spanishLogo,
}

export default class Navbar extends React.Component<{language: LanguageType}, any> {
	render() {
		return (
			<div className="Footer">
				<Container>
					<Row className="justify-content-between bigger-footer-logo">
						<Col>
							<img
								src={whiteLogos[this.props.language]}
								width="138"
								className="blue-footer-logo"
								alt="WHO - footer logo"
							/>
						</Col>
						<Col>
							<div className="text-right references">
								<div className={'ml-20'}>
									<a href={"https://www.who.int/about/policies/privacy"}>
										{i18n(this.props.language, "Privacy Policy")}
									</a>
								</div>
								<div>
									<a href={"https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing"}>
										{i18n(this.props.language, "About us")}
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Container>

				<p className="text-center copy">{i18n(this.props.language, "WHO Copyright")} {new Date().getFullYear()}</p>
			</div>
		);
	}
}
