import React from "react";
import { Link } from "react-router-dom";
import { Indicator } from "../../indicator";
import {i18n, LanguageType} from "../../i18n/utils";

interface IndicatorRowProps {
    indicator: Indicator;
    language: LanguageType;
}

export const enIconMeasurement: Record<string, JSX.Element> = {
    National: <i className="fas fa-globe-europe"></i>,
    'Sub-national': <i className="fas fa-landmark"></i>,
    'Population-based': <i className="fas fa-user-friends"></i>,
    'Facility-based': <i className="far fa-building"></i>,
};


export const frIconMeasurement: Record<string, JSX.Element> = {
    National: <i className="fas fa-globe-europe"></i>,
    'Infranational': <i className="fas fa-landmark"></i>,
    'En population': <i className="fas fa-user-friends"></i>,
    'Basé sur l’établis-sement': <i className="far fa-building"></i>,
};

export const esIconMeasurement: Record<string, JSX.Element> = {
    Nacional: <i className="fas fa-globe-europe"></i>,
    Subnacional: <i className="fas fa-landmark"></i>,
    Poblacional: <i className="fas fa-user-friends"></i>,
    'Establecimientos de salud': <i className="far fa-building"></i>,
};

export const typesClassName: Record<LanguageType, Record<string, string>> = {
    en: {
        input: "input",
        output: "output",
        outcome: "outcome",
        impact: "impact",
    },
    fr: {
        intrants: "input",
        extrants: "output",
        "résultats": "outcome",
        impact: "impact",
    },
    es: {
        insumo: "input",
        producto: "output",
        resultado: "outcome",
        impacto: "impact",
    }
}

export const iconMeasurements: Record<LanguageType, any> = {
    en: enIconMeasurement,
    fr: frIconMeasurement,
    es: esIconMeasurement,
}

export default class IndicatorRow extends React.Component<IndicatorRowProps, any> {
    expandFunc(id:number) {
        let selector = document.getElementById(`expand${id}`);

        if (selector!.style.display === "none" || selector!.style.display === "") {
            selector!.setAttribute('style', 'display:flex !important');
        } else {
            selector!.setAttribute('style', 'display:none !important');
        }
    }

    private i18n (key: string): string {
        return i18n(this.props.language, key);
    }

    render() {
        return (
            <div className="table-row-body d-flex flex-row justify-content-start" onClick={() => this.expandFunc(this.props.indicator!.id)}>
                {/* [ 1 / 9 ] - column  */}
                <div className="table-col-tr type-column align-self-center">
                    <p className={`type ${(typesClassName[this.props.language] as any)[this.props.indicator.type.toLowerCase()]}`}>
                        {this.props.indicator.type}
                    </p>
                </div>

                {/* [ 8 / 9 ] - column  */}
                <div className={`table-col-tr extended d-flex flex-row justify-flex-start flex-fill flex-wrap ${(typesClassName[this.props.language] as any)[this.props.indicator.type.toLowerCase()]}`}>
                    <p className="level align-self-center" title={this.props.indicator.measurement_level}>
                        {iconMeasurements[this.props.language][this.props.indicator.measurement_level]}
                    </p>

                    <p className="name" title={this.props.indicator.name}>
                        {this.props.indicator.name}
                    </p>

                    <p className="domain">
                        {this.props.indicator.domain}
                    </p>

                    <p className="continuum">
                        {this.props.indicator.continuum_of_care}
                    </p>

                    <p className="status">
                    {this.props.indicator.status ?  <span className="tag-status">
                            {this.props.indicator.status}
                        </span> : '' }

                    </p>

                    <p className="quality">
                        {this.props.indicator.quality_of_care}
                    </p>

                    <Link to={`/indicator-details/${this.props.indicator.id}`}  className="open align-self-center">
                        <p className="open align-self-center">
                            <i className="fas fa-share"></i>
                        </p>
                    </Link>


                    <div id={`expand${this.props.indicator!.id}`} className="expand-view d-flex flex-row justify-content-between align-items-start">
                        <div className="left-section">
                            <div className="numerator-denominator d-flex flex-row justify-content-start align-items-start">
                                <p className="numerator d-flex flex-column">
                                    <span className="name">{this.i18n("Numerator:")}</span>
                                    <span className="info">{this.props.indicator!.numerator}</span>
                                </p>

                                <p className="denominator d-flex flex-column">
                                    <span className="name">{this.i18n("Denominator:")}</span>
                                    <span className="info">{this.props.indicator!.denominator}</span>
                                </p>
                            </div>

                            <div className="list-service d-flex flex-row justify-content-between">
                                <p className={`crvs service d-flex align-items-start ${
                                        !this.props.indicator!.crvs && 'deactive'
                                    }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("CRVS")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${
                                        !this.props.indicator!.national_surveillance_system && 'deactive'
                                    }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("National surveillance system")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${
                                    !this.props.indicator!.hh_survey && 'deactive'
                                }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("HH Survey")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${
                                    !this.props.indicator!.health_facility_assessment && 'deactive'
                                }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("Health facility assessment")}</span>
                                </p>


                                <p className={`national-system service d-flex align-items-start ${
                                    !this.props.indicator!.hmis_dhis2 && 'deactive'
                                }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("HMIS/ DHIS2")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${
                                    !this.props.indicator!.other && 'deactive'
                                }`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("Other")}</span>
                                </p>
                            </div>
                        </div>
                        <div className="rigth-section">
							<div className="props d-flex flex-column justify-content-start align-items-start">
                                {/* You can use also this class: 'deactivate' */}
                                <p className={`national-system service d-flex align-items-start ${!this.props.indicator!.rmncah_hmis_data_analysis_and_use_toolkit_core_indicator && 'deactive'}`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${!this.props.indicator!.rmncah_hmis_data_analysis_and_use_toolkit_additional_indicator && "deactive"}`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator")}</span>
                                </p>

                                <p className={`national-system service d-flex align-items-start ${!this.props.indicator!.adolescent_health_indicators_list && "deactive"}`}>
                                    <i className="fas fa-check"/>
                                    <span>{this.i18n("ANC monitoring framework")}</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
