import React from "react";
import {
    createElement,
    Fragment,
    useEffect,
    useRef,
    useState
} from "react";
import { render } from "react-dom";

import { useSearchBox } from "react-instantsearch-hooks";
import { autocomplete, AutocompleteOptions } from "@algolia/autocomplete-js";
import { BaseItem } from "@algolia/autocomplete-core";
import "@algolia/autocomplete-theme-classic";

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
    className?: string;
};

type SetInstantSearchUiStateOptions = {
    query: string;
};

export function Autocomplete({
                    className,
                     ...autocompleteProps
                    }: AutocompleteProps) {
    const autocompleteContainer = useRef<HTMLDivElement>(null);

    const { query, refine: setQuery } = useSearchBox();

    const [instantSearchUiState, setInstantSearchUiState] = useState<
        SetInstantSearchUiStateOptions
        >({ query });

    useEffect(() => {
        setQuery(instantSearchUiState.query);
    }, [instantSearchUiState]);

    useEffect(() => {
        if (!autocompleteContainer.current) {
            return;
        }
        
        const autocompleteInstance = autocomplete({
            ...autocompleteProps,
            container: autocompleteContainer.current,
            initialState: { query },
            onReset() {
                setInstantSearchUiState({ query: ''});
            },
            onSubmit({ state }) {
                setInstantSearchUiState({ query: state.query });
            },
            onStateChange: ({prevState, state, setQuery}: any) => {
                if (prevState.query !== state.query) {
                    if (autocompleteProps.onStateChange) {
                        autocompleteProps.onStateChange({ prevState, state, setQuery } as any);                        
                    }
                    setInstantSearchUiState({
                        query: state.query
                    });
                }
            },
            renderer: { createElement, Fragment, render: render as any },
        });

        return () => autocompleteInstance.destroy();
    }, []);

    return <div className={className} ref={autocompleteContainer} />;
}