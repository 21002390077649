import React from "react";
import { Pagination } from "react-bootstrap";

interface IndicatorPaginationProps {
    pageCount: number;
    onPageClick: (pageNumber: number)=>void;
    active: number;
}

export default class IndicatorPagination extends React.Component<IndicatorPaginationProps, any> {

    handlePaginationItemClick(pageNumber: number) {
        this.props.onPageClick(pageNumber);
    }

    handlePaginationFirstClick() {
        this.props.onPageClick(1);
    }

    handlePaginationLastClick() {
        this.props.onPageClick(this.props.pageCount);
    }

    handlePaginationNextClick() {
        const nextPage = this.props.active + 1;
        if(nextPage <= this.props.pageCount) {
            this.props.onPageClick(nextPage);
        }
    }

    handlePaginationPreviousClick() {
        const previousPage = this.props.active - 1;
        if(previousPage > 0) {
            this.props.onPageClick(previousPage);
        }
    }

    render()  {
        let items = [];
        for (let pageNumber = 1; pageNumber <= this.props.pageCount; pageNumber++) {
            items.push(
                <Pagination.Item onClick={()=>this.handlePaginationItemClick(pageNumber)}
                                 key={pageNumber}
                                 active={pageNumber === this.props.active}>
                    {pageNumber}
                </Pagination.Item>,
            );
        }
        return (
            <nav className="nav-pag">
            <Pagination className="paginationStyle justify-content-center">
                <Pagination.First className="arrows-style" onClick={this.handlePaginationFirstClick.bind(this)}/>
                <Pagination.Prev className="arrows-style" onClick={this.handlePaginationPreviousClick.bind(this)}/>
                {items}
                <Pagination.Next className="arrows-style" onClick={this.handlePaginationNextClick.bind(this)}/>
                <Pagination.Last className="arrows-style" onClick={this.handlePaginationLastClick.bind(this)}/>
            </Pagination>
            </nav>
        );
    }
}
