import React from 'react';
import './Header.css';

import monitorIllustration from '../assets/monitor-illustration.jpg';
import { Container } from 'react-bootstrap';
import {i18n, LanguageType} from '../i18n/utils';

export default class Header extends React.Component<{language: LanguageType}, any> {

	componentDidMount() {
		(window as any).ga.pageview(window.location.pathname, window.location, "Initial Page");
	}

	private i18n (key: string): string {
		return i18n(this.props.language, key);
	}

    render(){
		return (
				<div className="Header">
					<Container>
						<div className="intro-section">

							<p>	{this.i18n("MONITOR is technical advisory group to WHO on measurement, metrics and monitoring of maternal and newborn health co-convened by the Departments of Maternal, Newborn, Child and Adolescent Health (MCA) and Sexual and Reproductive Health and Research (SRH).")}
								<a href={'https://www.who.int/data/maternal-newborn-child-adolescent/monitor'}>{this.i18n(" See more ")}</a>
							</p>
							<br/>
							<p>{this.i18n("The MoNITOR online Toolkit includes:")}
								<ul>
									<li>{this.i18n("A searchable indicator database which contain 167 indicators related to maternal and newborn health, including core, optional and aspirational indicators")}</li>
									<li>{this.i18n("Indicator metadata reference sheets for 25 core indicators")}</li>
									<li>
										{this.i18n("Recommendations and case studies on indicator testing and validation")}
									</li>
								</ul>
							</p>
						</div>

						<img
							src={monitorIllustration}
							width="380"
							className="mother-img"
							alt="Monitor Illustration"
						/>
					</Container>
				</div>
		);
	}
};
